<template>
    <div class="underLogoPhrase">
        <h2 class="font-weight-bold mb-4 centers">Les Animaux</h2>
        <p>
            Selon les dates de mise à jour, certains animaux visibles sur le site ont déjà pu être
            adoptés. N'hésitez pas à contacter les différents refuges.
        </p>
    </div>

    <div class="container-fluid">
        <div class="row responsive">
            <button class="bluenow buttonadaptatif responsive" @click="filtrerAnimaux('all')">Tous</button>
            <button class="bluenow buttonadaptatif responsive" @click="filtrerAnimaux('chien')">Chiens</button>
            <button class="bluenow buttonadaptatif responsive" @click="filtrerAnimaux('chat')">Chats</button>
            <button class="bluenow buttonadaptatif responsive" @click="filtrerAnimaux('nasc')">NAC</button>
        </div>


        <div class="row">
            <div class="col-lg-3" v-for="animau in animauxFiltres" :key="animau.id">
                <a :href="animau.redirect" target="_blank">
                    <div class="taillemini">
                        <img :src="animau.image" :alt="'photo du ' + animau.type + ' ' + animau.name"
                            :class="'tailleimg'" :title="animau.name + ' ' + animau.race" />
                    </div>
                    <div class="endessous" style="margin-top: 10px !important;margin-bottom: 30px;">
                        <h4 class="namedogtitleback">{{ animau.name }}</h4>
                        <h6>{{ animau.race }}</h6>
                        <h6><strong> Age: {{ calculateAge(animau.age) }}</strong></h6>
                        <i class="fas fa-plus-circle margin5"> </i> <b>{{ animau.refuge_name }}</b>
                    </div>
                </a>
            </div>
        </div>
    </div>

</template>


<script>
import convertAge from '../components/getAge.js';
export default {
    data() {
        return {
            animaux: [],
            animauxFiltres: []
        };
    },
    mounted() {
        fetch('https://admin.les-refuges.fr/generateApi')
            .then(response => response.json())
            .then(data => {
                // Combine all animals into a single array
                this.animaux = [
                    ...data.chiens.map(animal => ({ ...animal, type: 'chien' })),
                    ...data.chats.map(animal => ({ ...animal, type: 'chat' })),
                    ...data.nasc.map(animal => ({ ...animal, type: 'nasc' }))
                ];
                this.appliquerFiltre();
            })
            .catch(error => {
                console.error('Il y a eu un problème avec votre requête :', error.message);
            });
    },
    methods: {
        appliquerFiltre() {
            const type = this.$route.query.type || 'all';
            this.animauxFiltres = type === 'all'
                ? this.animaux
                : this.animaux.filter(animal => animal.type === type);
        },
        filtrerAnimaux(type) {
            this.$router.push({ path: this.$route.path, query: { type } });
        },
        calculateAge(dateOfBirth) {
            return convertAge(dateOfBirth);
        }
    },
    watch: {
        '$route.query.type': 'appliquerFiltre'
    }
};
</script>