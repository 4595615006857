<template>
  <nav class="navbar navbar-expand-lg navbar-light " style="width: 100vw;top: 0px;">
    <a href="/">
      <img class="logo" src="../assets/images/logorefuge.png" width="200px"
        alt="logo de la spa de la spa de Savoie  / Chambery - Auvergne-Rhône-Alpes">
    </a>

    <a href="/" class="logotexte">
      <h1> <span class="margegauche">Les - Refuges </span></h1>
    </a>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
      aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse groupemid" id="navbarSupportedContent">
      <ul class="navbar-nav mr-auto">
        <li class="nav-item ">
          <router-link class="nav-link" to="/" @click="toggleNavbar()">Accueil</router-link>
        </li>
        <p class="nav-link taille"><i class="fas fa-paw"></i></p>
        <li class="nav-item">
          <router-link class="nav-link" to="/Animaux" @click="toggleNavbar()">Les Animaux</router-link>
        </li>
        <p class="nav-link taille"><i class="fas fa-paw"></i></p>
        <li class="nav-item">
          <router-link class="nav-link" :to="{ path: '/', hash: '#refuge' }" @click="toggleNavbar()">Les
            Refuges</router-link>
        </li>
        <p class="nav-link taille"><i class="fas fa-paw"></i></p>
        <li class="nav-item">
          <router-link class="nav-link" to="/" @click="toggleNavbar()">News</router-link>
        </li>
        <p class="nav-link taille"><i class="fas fa-paw"></i></p>
        <li class="nav-item">
          <router-link class="nav-link" :to="{ path: '/', hash: '#contact' }"
            @click="toggleNavbar()">Contact</router-link>
        </li>
      </ul>
    </div>
  </nav>


  <!-- route outlet -->
  <!-- component matched by the route will render here -->
  <router-view></router-view>
</template>

<style src="../assets/css/template.css"></style>
<style src="../assets/css/header.css"></style>
<script>


export default {
  methods: {
    toggleNavbar() {
      if (window.innerWidth < 500) {
        var navbarToggler = document.querySelector('.navbar-toggler');
        navbarToggler.click();
      }
    },
    toggleNavbarDisplay() {
      if (window.innerWidth < 500) {
        var navbarToggler = document.querySelector('.navbar-toggler');
        navbarToggler.click();
      }
    },
  }
}

</script>