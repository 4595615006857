<template>
  <div class="homeContainer">

    <h2 class="font-weight-bold mb-4 centers">Nos animaux à adopter</h2>

    <div class="myrow">
      <router-link to="/Animaux?type=chien">
        <div class="backchien"></div>
      </router-link>
      <router-link to="/Animaux?type=chat">
        <div class="backchat"></div>
      </router-link>
      <router-link to="/Animaux?type=nasc">
        <div class="backnasc"></div>
      </router-link>
    </div>

    <div class="bluenow buttonadaptatif" id="refuge">
      <router-link to="/animaux" style="line-height:50px; color:white">Voir tous les animaux
      </router-link>
    </div>
  </div>
  <div class="seperation"></div>

  <div class="homeContainerRefuge">
    <h2 class="font-weight-bold mb-4 centers">Les Refuges</h2>

    <table class="table" id="contact">
      <tbody>
        <tr>
          <td>
            <img :src="'https://spa-dunkerque.fr/img/newlogo.d6253520.png'" :class="'tailleimglogo'" style="" />
          </td>
          <td class='titleRefuge'>Spa-Dunkerque</td>
          <td class='titleRefuge'> <a href="https://spa-dunkerque.fr" target="_blank">+ d'informations</a></td>
        </tr>
        <tr>
          <td>
            <img :src="'https://www.spadesavoie.fr/img/newlogo.8e6fe85e.png'" :class="'tailleimglogo'" style="" />
          </td>
          <td class='titleRefuge'>Spa-Savoie</td>
          <td class='titleRefuge'> <a href="https://spadesavoie.fr" target="_blank">+ d'informations</a></td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="seperation"></div>
  <div class="homeContainerRefuge">
    <h2 class="font-weight-bold mb-4 centers">Contact</h2>
    <h5>
      <center>lesrefuges.animalier@gmail.com</center>
    </h5>
    <div class="ctcimg"></div>
  </div>


</template>
