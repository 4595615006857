import { createApp } from 'vue';
import { createRouter, createWebHistory } from 'vue-router';

import App from './App.vue';

import Home from './view/homeVue.vue';
import Animaux from './view/animauxVue.vue';
import Sos from './view/sosVue.vue';

const routes = [
    { path: '/redirect', redirect: '/' },
    { path: '/', component: Home },
    { path: '/sos', component: Sos },
    { path: '/animaux', component: Animaux },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        } else if (to.hash) {
            return {
                el: to.hash,
                behavior: 'smooth',
            };
        } else {
            return { top: 0 };
        }
    },
});

createApp(App).use(router).mount('#app');